import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Header.scss'

function Header() {

    return (
        <div>
            <div className="container">
                <div className="masthead-subheading txt anim-text-flow">Digital Transformation</div>
                <div className="masthead-heading text-uppercase txt anim-text-flow">Application Architecture and Design Specialist</div>
                <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a>
            </div>
        </div>
    )
}

export default Header