import React from 'react';


function Contact() {

    return (
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Get in touch</h2>
                <h3 className="section-subheading"> Ready to start your next project with us? Send us an email and we will get back to you as soon as possible!</h3>
            </div>
            <div className="container">
                <div className="text-center">
                    <i className="fa fa-envelope fa-3x mb-3 text-muted"></i>
                    <a className="d-block" href="mailto:solutions@onyxapps.com.au">solutions@onyxapps.com.au</a>
                </div>
            </div>

        </div>
    )
}

export default Contact