import React from 'react';

function Footer() {

    return (
        <div className="container">
            <div className="small text-center text-muted">Copyright © 2023 - OnyxApps</div>
        </div>
    )
}

export default Footer