import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header'
import NavBar from './components/NavBar'
import About from './components/About'
import Services from './components/Services'
import Footer from './components/Footer'
import Contact from './components/Contact';

class App extends React.Component {

  render(){
    return (
      <div id="page-top">
        <NavBar />
        <header className="masthead">
            <Header />
        </header>
        
        <section className="page-section" id="services">
            <Services />
        </section>
      
        <section className="page-section" id="about">
            <About />
        </section>
      
        <section className="page-section" id="contact">
            <Contact />
        </section>
      
        <footer className="footer py-4">
            <Footer />
        </footer>

      </div>
    );
  }

}

export default App;
