import React from 'react'

function NavBar() {

    return (
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="#page-top">
                        <img className="d-inline-block align-top" src="assets/img/onyxapps_logo9.svg" alt="" />
                        OnyxApps
                    </a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><i className="fa fa-bars ml-1"></i></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav text-uppercase ml-auto align-middle">
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#services">What we do</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">Who we are</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
    )
}

export default NavBar