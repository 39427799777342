import React from 'react';

function About() {

    return (
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Who We Are</h2>
                <p className="section-subheading font-weight-light">We are a group of IT consultants based in Australia.  
                    Over the decades of our professional lives, we gained invaluable experience in developing solutions for some of the most complex and large IT environments, 
                    both in Public and Private sector. We believe in “Make everything as simple as possible, but not simpler” philosophy. 
                    We apply this philosophy  in our approach in developing IT application architecture and UX to deliver applications that 
                    solve complex problems as well as assisting businesses along with their digital transformation journey.  
                    Your success is our success!</p>
            </div>
        </div>
    )
}

export default About