import React from 'react';

function Services() {

    return (
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">What We Do</h2>
                <h3 className="section-subheading font-weight-light">We offer bespoke application architecture and design services.</h3>
            </div>
            <div className="row text-center">
                <div className="col-md-4">
                    <span className="fa-stack fa-4x"><i className="fa fa-circle fa-stack-2x text-primary"></i><i className="fa fa-users fa-stack-1x fa-inverse"></i></span>
                    <h4 className="my-3">User-Centered Design</h4>
                    <p className="font-weight-light">We work with you as one-team to understand your unique business requirements, constraints and business processes. We conduct workshops with your
                    SMEs to understand UX requirements before developing service design options by re-imagining your business processes keeping your users first</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x"><i className="fa fa-circle fa-stack-2x text-primary"></i><i className="fa fa-lock fa-stack-1x fa-inverse"></i></span>
                    <h4 className="my-3">DevOps/DevSecOps</h4>
                    <p className="font-weight-light">We are experienced in building automated continuous integration and continuous delivery pipelines for on-premises and cloud environments. 
                    We develop solutions that are secure by design. We use industry best practices to secure the systems end-to-end. .</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x"><i className="fa fa-circle fa-stack-2x text-primary"></i><i className="fa fa-cloud fa-stack-1x fa-inverse"></i></span>
                    <h4 className="my-3">Cloud-Native</h4>
                    <p className="font-weight-light">We are experienced in using Microservice architecture principles and Cloud Native technologies. </p>
                </div>
            </div>
        </div>
    )
}

export default Services